var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Dialog",
    {
      attrs: { name: _vm.name },
      on: { close: _vm.clearPickers },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function () {
            return [_c("div", [_vm._v("Перенос тайм слота")])]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-row",
        { class: { "flex-w": _vm.isMobile } },
        [
          _c(
            "el-form",
            {
              ref: "ShiftSlotForm",
              staticClass: "drivers-slots-form",
              attrs: {
                model: _vm.gettingSlotsForm,
                rules: _vm.rulesFormGettingSlots,
                "label-width": "300px",
                "label-position": "top",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c("iq-select-date-form", {
                attrs: {
                  size: "fullWidth",
                  label: "Дата прибытия:",
                  prop: "day",
                  type: "date",
                  placeholder: "Укажите день",
                  format: "dd/MM/yyyy",
                  options: _vm.pickerOptions,
                },
                model: {
                  value: _vm.gettingSlotsForm["day"],
                  callback: function ($$v) {
                    _vm.$set(_vm.gettingSlotsForm, "day", _vm._n($$v))
                  },
                  expression: "gettingSlotsForm['day']",
                },
              }),
              _c("iq-select-form", {
                attrs: {
                  "full-width": "",
                  label: "Время прибытия:",
                  prop: "time",
                  message: "Для выбора времени укажите день",
                  disabled: !_vm.gettingSlotsForm["day"],
                  placeholder: "Укажите время",
                  options: _vm.timeSelectOptions,
                },
                model: {
                  value: _vm.gettingSlotsForm["time"],
                  callback: function ($$v) {
                    _vm.$set(_vm.gettingSlotsForm, "time", $$v)
                  },
                  expression: "gettingSlotsForm['time']",
                },
              }),
              _c(
                "el-form-item",
                { staticStyle: { "margin-top": "32px" } },
                [
                  _c(
                    "iq-button",
                    {
                      attrs: { color: "main-dark", size: "fullWidth" },
                      on: {
                        onClick: function ($event) {
                          return _vm.submitForm("ShiftSlotForm")
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-upload2 button-icon" }),
                      _vm._v(" Запросить тайм слот "),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }